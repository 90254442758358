import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCourseFromUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
            .post('/users/delete-course-from-user', {
              course_id: data.userCourseId,
              user_id: data.user_id
            })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchCourses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
            .get('/users/courses/', { params: queryParams })
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/user/users', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
